import { Component } from '@angular/core';
import { StorageService } from './core/services/utils/storage.service';
import { LanguageService } from './core/services/language/language.service';
import { CompanyService } from './core/services/user/company.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LoadingScreenService } from './core/services/loading-screen/loading-screen.service';
import { UserService } from './core/services/user/user.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { hideFooterUrls } from './core/configurations/configurations';
import { RouterService } from './core/services/router/router.service';
import { ManagementModalService } from './core/services/utils/management-modal/management-modal.service';
import { AuthenticationService } from './core/authentication/authentication.service';
import { GenericChatService } from './core/services/chat/generic-chat.service';
import { RailAppService } from './core/services/rail-app/rail-app.service';

// tslint:disable-next-line:ban-types
declare const gtag: Function; // <------------Important: the declartion for gtag is required!

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  client: any;
  loading = this.loadingScreenService.loadingStatus;
  hideFooter$: Observable<boolean>;
  userLogin = false;
  isVeci = false;
  logs: string[] = [];
  railAppMessageControl = false;
  showBackToUserSearchBtn = false;

  constructor(
    public languageService: LanguageService,
    private storage: StorageService,
    private companyService: CompanyService,
    private router: Router,
    private loadingScreenService: LoadingScreenService,
    private userService: UserService,
    private routerPopServices: RouterService,
    private managementModalService: ManagementModalService,
    private authenticationService: AuthenticationService,
    private chatService: GenericChatService,
    private railAppService: RailAppService
  ) {
    if (this.userService.isRailAPP() && window.opener) {
      this.railAppMessageControl = true;
      this.railAppService.railAppMessageControl(this.authenticationService);
    }
    this.routerPopServices.getTitleTab();
    this.routerPopServices.controlIBCCParametersToURLAPIStart();
    this.storage.cleanInterval();

    // Suscribirse a los cambios de ruta
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.showBackToUserSearchBtn = this.initShowBackToUserSearchBtn();
      });

    this.authenticationService.getIsLoggeIn$().subscribe((res) => {
      this.userLogin = res;
      if (res) {
        setTimeout(() => {
          (function (c, l, a, r, i, t, y) {
            c[a] =
              c[a] ||
              function () {
                (c[a].q = c[a].q || []).push(arguments);
              };
            t = l.createElement(r);
            t['async'] = 1;
            t['src'] = 'https://www.clarity.ms/tag/' + i;
            y = l.getElementsByTagName(r)[0];
            if (y?.parentNode) {
              y.parentNode.insertBefore(t, y);
            }
          })(
            window,
            document,
            'clarity',
            'script',
            '8rafzyutyh',
            document.createElement('script') as HTMLElement,
            document.getElementsByTagName('script')[0] as Element
          );
        }, 3000);
      }
    });
    this.userService.currentUserSubject.subscribe((res) => {
      const clientCss = res?.css;
      if (clientCss) {
        document.getElementById('dynamic-style')?.append(`:root {\
            /** admin keys */\
            --color-primary-rgb: ${this.converColorInrgba(clientCss?.pc)};\
            --color-primary: ${clientCss?.pc};\
            --color-second: ${clientCss?.sc};\
            --color-third: ${clientCss?.tc};\
            --color-hover: ${clientCss?.bhc};\
            --color-button: ${clientCss?.bc};\
          }`);
      }
    });

    // load styles by client.
    this.companyService.companyData$.subscribe((data) => {
      if (data) {
        this.client = data;
        document
          .getElementById('appFavicon')
          ?.setAttribute('href', this.client.favicon);
        document.getElementById('dynamic-style')?.append(`:root {\
            /** admin keys */\
            --color-primary-rgb: ${this.converColorInrgba(
              this.client?.css.pc
            )};\
            --color-primary: ${this.client.css?.pc};\
            --color-second: ${this.client.css?.sc};\
            --color-third: ${this.client.css?.tc};\
            --color-hover: ${this.client.css?.bhc};\
            --color-button: ${this.client.css?.bc};\
          }`);
        setTimeout(() => this.loadingScreenService.stopLoading(), 500);
      } else {
        this.loadingScreenService.startLoading();
        this.companyService.getCompanyData();
      }
      this.isVeci = this.userService.checkIsUserVeci();
    });

    // initialize translate service.
    this.languageService.initialize();

    this.hideFooter$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        let show = true;
        hideFooterUrls.forEach((url: string) => {
          if (event.url.includes(url)) {
            show = false;
          }
        });
        return show;
      })
    );

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        tap(() => this.managementModalService.deleteAllStackElement())
      )
      .subscribe();

    this.router.events
      .pipe(
        filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd),
        filter(
          (res) =>
            res.id === 1 &&
            res.url === res.urlAfterRedirects &&
            this.authenticationService.isLoggedIn()
        ),
        switchMap(() => this.userService.getUserData())
      )
      .subscribe({
        next: (res) => {
          if (!res || (res && Object.keys(res).length === 0)) {
            this.authenticationService.logout();
          }
        },
      });

    /** INICIO: código para realizar un seguimiento de la vista de página mediante gtag.js */
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe({
        next: (event: NavigationEnd) => {
          gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects,
          });
        },
      });
    /** END : Código para rastrear la vista de página usando gtag.js */

    this.router.events
      .pipe(
        filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd),
        filter((res) => this.authenticationService.isLoggedIn())
      )
      .subscribe((res: any) => {
        if (
          this.userService.isRailAPP() &&
          !this.railAppMessageControl &&
          window.opener
        ) {
          this.railAppMessageControl = true;
          this.railAppService.railAppMessageControl(this.authenticationService);
        }

        if (this.chatService.isActive()) {
          if (!document.getElementById('my-script')) {
            window['frogedSettings'] = {
              appId: this.userService.getAppIdFrogedChatActive(),
              languageCode: this.languageService.getLocaleCode() || 'ES',
            };
            (window['Froged'] = function (a: any, b: any, c: any) {
              window['Froged'].c(a, b, c);
            }),
              (window['Froged'].q = []),
              (window['Froged'].c = function (a: any, b: any, c: any) {
                window['Froged'].q.push([a, b, c]);
              });
            const body = document.body;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = '';
            script.src = 'https://jaxmso.sdk.froged.com';
            script.id = 'my-script';
            body.appendChild(script);
            // Carrefour chat by Froged
            setTimeout(() => this.loginAsAgency(), 3000);
          } else {
            setTimeout(() => this.loginAsAgency(), 3000);
          }
        }
      });
  }

  converColorInrgba(pc: string): string {
    const r = parseInt(pc?.slice(1, 3), 16);
    const g = parseInt(pc?.slice(3, 5), 16);
    const b = parseInt(pc?.slice(5, 7), 16);
    return `${r},${g},${b}`;
  }

  typeAgency(): string {
    const name = this.userService.getNameCPN();
    let option = 'Propia';
    if (name?.includes('no_iata')) {
      option = 'Asociada';
    } else if (name?.includes('web')) {
      option = 'Franquicia';
    }
    return option;
  }

  loginAsAgency(): void {
    this.chatService.set(
      {
        userId: this.userService.getAgencyId(),
        email: this.userService.getAgencyEmail(),
        name: this.userService.getAgencyName(),
        TIPOAGENCIA: this.typeAgency(),
        CodigoAgencia: this.userService.getAgencySourceId(),
      },
      'froged'
    );
    setTimeout(() => {
      // FROGED HA AÑADIDO ESTAS CLASES Y APARECE A LA IZQUIERDA
      document.getElementById('fg_bubble')?.classList.remove('left');
      document.getElementById('fg_iframe')?.classList.remove('left');

      if (this.userService.userIsCallCenter()) {
        document.getElementById('fg_bubble')?.classList.add('isCallCenter');
      }
    }, 1000);
    // Unread messages listener
    this.chatService.onInboxUpdate('froged');
  }

  private initShowBackToUserSearchBtn() {
    return this.userService.userIsLleegoInternal()
            && this.router.url !== "/user-search";
      ;
  }
}
