<section class="noResult">
  <div class="icons">
    <svg-icon *ngIf="options.icon" class="font-flight" [src]="options.icon">
    </svg-icon>
  </div>

  <div class="options">
    <div class="no-result-header">
      <p *ngIf="options.title" data-cy="message-noResult">
        {{ options.title | translate }}
      </p>
      <h1 *ngIf="options.subTitle" data-cy="description-noResult">
        {{ options.subTitle | translate }}
      </h1>
    </div>
    <div class="sugerencias">
      <div *ngIf="options.titleSearch" class="sugerencias-header">
        <svg-icon src="assets/images/icons/sugerencia.svg"> </svg-icon>
        <h1>{{ options.titleSearch | translate }}</h1>
      </div>
      <ng-container *ngIf="options.options">
        <ul *ngFor="let option of options.options">
          <li>
            {{ option | translate }}
          </li>
        </ul>
      </ng-container>
    </div>
    <button
      *ngIf="options.button"
      pRipple
      (click)="resetAll()"
      class="no-result-button btn-primary-alternative"
      data-cy="reset-filters"
    >
      {{ options.button | translate }}
    </button>
  </div>
</section>
